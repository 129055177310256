import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'
import {Controller, useForm, useFormContext} from 'react-hook-form'

//import { IUserPersonShow } from '../../../../../commons/interfaces/userPerson/userPersonShow';
import {useQueryClient} from 'react-query'
//import { InputUpload } from '../../../../../_metronic/layout/components/input/InputUpload';
import {Download, Trash} from '../../../../../../commons/icons'
import ProgressBar from '../../../../../../commons/components/progressBar/ProgressBar'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../../store/store'

interface IProps {
  formDisabled?: boolean
}
export const GeneralInformation = ({formDisabled}: IProps) => {
  const date = new Date()
  const day = `0${date.getDate()}`.slice(-2)
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const year = date.getFullYear()

  const {userLogin} = useSelector((state: RootState) => state.auth)
  console.log(userLogin)

  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: {errors, touchedFields, isSubmitting, isValid},
    setError,
  } = useFormContext()

  /*     useEffect(() => {
            setValue('state', '1');
        }, [setValue]); */

  return (
    <div className=''>
      <div className='mt-6 ms-md-4'>
        <h2 className='title-form fw-bold mb-5'>Información del evento</h2>

        <div className='row  mb-6'>
          <div className='fv-row col-md-6  mb-6 m-md-0'>
            <label className='form-label fs-6 required text-dark'>Código del evento</label>
            <input
              maxLength={140}
              placeholder=''
              disabled={formDisabled}
              {...register('code')}
              // defaultValue={result?.data?.result?.document_number}
              className={clsx(
                'form-control ',
                {'bg-transparent': !formDisabled},
                {'is-invalid': errors.code},
                {
                  'is-valid': touchedFields.code && !errors.code,
                }
              )}
              type='text'
              autoComplete='off'
            />
            {errors.code && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.code.message as string}
                </span>
              </div>
            )}
          </div>
          <div className='fv-row col-md-6 '>
            <label className='form-label fs-6 required text-dark'>Nombre del evento </label>
            <input
              maxLength={140}
              placeholder=''
              disabled={formDisabled}
              {...register('name')}
              //={result?.data?.result?.name}
              className={clsx(
                'form-control',
                {'bg-transparent': !formDisabled},
                {'is-invalid': errors.name},
                {
                  'is-valid': touchedFields.name && !errors.name,
                }
              )}
              type='text'
              name='name'
              autoComplete='off'
              //onChange={() => setResponseErrors(null)}
            />
            {errors.name && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.name.message as string}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className='row  mb-6'>
          <div className='fv-row col-md-6  mb-6 m-md-0'>
            <label className='form-label fs-6 required text-dark'>
              Fecha de creación del evento
            </label>

            <input
              maxLength={140}
              placeholder=''
              disabled={!formDisabled}
              defaultValue={`${day}/${month}/${year}`}
              className={clsx('form-control form-control-solid')}
              type='text'
              name='lastname'
              autoComplete='off'
            />
            {errors.lastname && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.lastname.message as string}
                </span>
              </div>
            )}
          </div>
          <div className='fv-row col-md-6 '>
            <label className='form-label fs-6  text-dark'>ID-AS400</label>

            <input
              maxLength={140}
              placeholder=''
              disabled={formDisabled}
              //defaultValue={result?.data?.result?.phone}
              {...register('code_as400')}
              className={clsx(
                'form-control ',
                {'bg-transparent': !formDisabled},
                {'is-invalid': errors.code_as400},
                {
                  'is-valid': touchedFields.code_as400 && !errors.code_as400,
                }
              )}
              type='text'
              name='code_as400'
              autoComplete='off'
            />
            {errors.code_as400 && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.code_as400.message as string}
                </span>
              </div>
            )}
          </div>
        </div>

        <div className='row  mb-6'>
          <div className='fv-row col-md-6  mb-6 m-md-0'>
            <label className='form-label fs-6  required text-dark'>Estado del evento </label>
            <select
              disabled={formDisabled}
              className='form-select bg-transparent'
              aria-label='Disabled select example'
              {...register('status')}
              //defaultValue={result?.data?.result?.phone}
            >
              <option value='1'>Activo</option>
              <option value='2'>Inactivo</option>
            </select>
          </div>

          <div className='fv-row col-md-6 '>
            <label className='form-label fs-6  text-dark'>Usuario creador del evento</label>

            <input
              maxLength={140}
              placeholder=''
              disabled={!formDisabled}
              defaultValue={`${userLogin?.firstname} ${userLogin?.lastname}`}
              className={clsx(
                'form-control form-control-solid',
                {'bg-transparent': !formDisabled},
                {'is-invalid': errors.user_person},
                {
                  'is-valid': touchedFields.user_person && !errors.user_person,
                }
              )}
              type='text'
              autoComplete='off'
            />
            {errors.user_person && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.user_person.message as string}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <hr className='hr' />

      <div className='mt-6 ms-md-4'>
        <h2 className='title-form fw-bold mb-5'>Asistencia</h2>

        <div className='row  mb-6'>
          <div className='fv-row col-md-6 '>
            <label className='form-label fs-6  text-dark'>Nivel de asistencia</label>
            <div className=''>
              <ProgressBar bgcolor='#00A160' height={5} progress={0} />
            </div>

            {/*  <input
                            placeholder=''
                            disabled={formDisabled}
                            // defaultValue={result?.data?.result?.email}
                            {...register('email')}
                            className={clsx(
                                'form-control',
                                { 'bg-transparent': !formDisabled },
                                { 'is-invalid': errors.email },
                                {
                                    'is-valid': touchedFields.email && !errors.email,
                                }
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                        //onChange={() => setResponseErrors(null)}
                        /> */}
            {/* {errors.email && (
                            <div className='fv-plugins-message-container'>
                                <span className='text-danger' role='alert'>{errors.email.message as string}</span>
                            </div>
                        )} */}
          </div>
          <div className='fv-row col-md-6 '></div>
        </div>

        <div className='row  mb-6'>
          <div className='fv-row col-md-6 '>
            <label className='form-label fs-6 required text-dark'>Sociedad del evento</label>
            <select
              disabled={formDisabled}
              className='form-select bg-transparent '
              aria-label='Disabled select example'
              {...register('event_society_id')}
              // defaultValue={result?.data?.result?.status}
            >
              {userLogin?.event_societies.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}
