import React, { useCallback, useState } from 'react'
import {IconClose, IconMagnifier} from '../../icons'
import { debounce } from '../../helpers/general'

interface IProps {
  name?: string
  isAutomatic?: boolean
  manageSearch: React.Dispatch<React.SetStateAction<string>>
}

const SearchFilter = ({ name, isAutomatic, manageSearch }: IProps) => {
  const [search, setSearch] = useState<string>('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
    if (isAutomatic)
      debounced(value);
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter')
      manageSearch(search);
  }

  const handleClean = () => {
    setSearch('');
    manageSearch('');
  }

  const debounced = useCallback(
    debounce((value) => {
      manageSearch(value)
    }, 300),
    []
  );

  return (
    <div className='card-title supplier-title'>
      <div className='d-flex align-items-center position-relative my-1'>
        <IconMagnifier className='fs-1 position-absolute ms-4' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control px-14'
          placeholder={`Buscar ${name || ''}`}
          value={search}
          onKeyDown={handleKeyPress}
          onChange={handleSearch}
        />
        <IconClose
          className='position-absolute top-50 translate-middle-y end-0 me-4'
          role='button'
          onClick={handleClean}
        />
      </div>
    </div>
  )
}

export default SearchFilter
