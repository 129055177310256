import {useEffect, useState} from 'react'
import {
  IconPrint,
} from '../../../../../commons/icons'
import {GeneralDataform, ProductsToShow} from '../../../../../commons/interfaces/order/orderView'
import jsPDF from 'jspdf'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import autoTable from 'jspdf-autotable'
import {headersPdf} from '../../config/OrderViewConfig'
import useModal from '../../../../../commons/hooks/useModal'
import { ModalConfirmation } from '../../../../../commons/components/modals/ModalConfirmation'
import TableOrderListProducts from '../tables/TableOrderListProducts'

interface OrderListProductsProps {
  products: ProductsToShow[]
  order: GeneralDataform
}

const OrderListProducts = ({products, order}: OrderListProductsProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { show: showModalConfirmation, handleClose: close, handleShow } = useModal();
  const [formattedProducts, setFormattedProducts] = useState<ProductsToShow[]>([]);

  const generarPdf = () => {
    const unit = 'pt'
    const size = 'A4'
    const orientation = 'landscape'
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    const title = `Listado de productos del pedido: ${order?.firstname} ${order?.lastname}`
    const headers = [headersPdf]
    doc.text(title, marginLeft, 100)
    doc.addImage(toAbsoluteUrl('/media/logos/logo.png'), 'PNG', 40, 20, 50, 50)
    autoTable(doc, {html: '#my-table-report'})
    autoTable(doc, {
      startY: 120,
      head: headers,
      body: products.map((item) => {
        return [
          item.sku,
          item.name,
          item.brand,
          item.quantity ? item.quantity.toString() : '-',
          item.unit_measure,
          item.conversion_unit,
          item?.entity_supplier?.business_name || '',
          item.line,
        ]
      }),
      headStyles: {lineWidth: 0.5, fillColor: [0, 161, 96], textColor: [255, 255, 255]},
    })
    window.open(doc.output('bloburl'), '_blank')
  }

  const handleConfirmation = () => {
    generarPdf()
  }

  // useeffect to format the products
  // the formatted products is neccesary to sort the products by supplier
  useEffect(() => {
    // format the products
    const newProducts = products.map((product) => {
      return {
        ...product,
        supplier: product.entity_supplier?.business_name || '',
      }
    });
    // set the formatted products
    setFormattedProducts(newProducts);
  }, [products])

  return (
    <>
      <h2 className='title-form fw-bold mb-5'>Listado de productos del pedido</h2>
      <div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap flex-md-nowrap border-0 mt-6'>
        <div className='card-title '>
          <input
            type='text'
            className='form-control  w-200 w-sm-250px'
            placeholder='Buscar producto del pedido'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <div className='d-flex  flex-wrap   gap-3 mt-4 mt-sm-0'>
          <button
            onClick={handleShow}
            type='button'
            className='btn  btn-primary  w-100 w-sm-175px'
          >
            <IconPrint className='me-3' stroke='#fff' />
            Imprimir
          </button>
        </div>
      </div>
      <div className='mt-6'>
      <div className='overflow-auto h-500px'>
          {/* Table */}
          <TableOrderListProducts
            searchValue={searchValue}
            data={formattedProducts}
          />
        </div>
      </div>
      <ModalConfirmation
        title='¿Estás seguro que deseas imprimir el pedido del usuario?'
        textButton='Si, imprimir'
        onHide={close}
        show={showModalConfirmation}
        onClick={handleConfirmation}
      />
    </>
  )
}

export default OrderListProducts
