import { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import SVG from 'react-inlinesvg';
import { MainTitle } from '../../../../commons/components/Title/MainTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IconPencil from '../../../../commons/icons/IconPencil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import eventService from '../../../../services/event';
import { GeneralInformationUpdate } from './components/tabs/GeneralInformationUpdate';
import { AdditionalInformationUpdate } from './components/tabs/AdditionalInformationUpdate';
import { format, parseISO, startOfDay } from 'date-fns';
import { ModalSuccess } from './components/modals/ModalSuccess';
import { IconCancelCircle, IconCheckCircle, IconHeart, IconShoppingBag, IconUsers } from '../../../../commons/icons';
import { ModalAlert } from '../../../../commons/components/modals/ModalAlert';
import { ModalConfirmation } from '../../../../commons/components/modals/ModalConfirmation';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { toast } from 'react-toastify';
import { useErrorHandling } from '../../../../commons/hooks/useErrorHandling';
import { ModalErrors } from '../../../../commons/components/modals/ModalErrors';
import EventPageSettings from './components/tabs/EventPageSettings';
import { ContentEventOpening, ScheduleForm } from '../../../../commons/interfaces/event/createEvent';
import { getDateLabel } from '../../../../commons/helpers/general';
import AssignedUsers from './components/tabs/AssignedUsers';
import { Coordinates } from './components/maps/MapComponent';

// Define the schema for the form
const updateEventSchema = Yup.object().shape({
    name: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('Nombre del evento es requerido'),
    code: Yup.string()
        .max(140, 'Máximo 140 caracteres')
        .required('Codigo del evento es requerido')
        .test('no-espacios', 'El código no debe contener espacios en blanco', (value) => {
            if (value && /\s/.test(value)) {
                return false;
            }
            return true;
        }),
    code_as400: Yup.string(),
    start_date: Yup.string(),
    end_date: Yup.string(),
    created_at: Yup.string(),
    event_society_id: Yup.number()
        .required('Sociedad del evento es requerido'),
    status: Yup.string()
        .required('Estado del evento es requerido'),
    content_event_opening: Yup.array().min(1),
    url: Yup.string(),
    email: Yup.string()
        .required('Email es requerido')
        .email('Formato de correo inválido')
        .min(3, 'Minimo 3 caracteres')
        .max(50, 'Máximo 140 caracteres'),
    website_status: Yup.string(),
    address: Yup.string(),
    reference: Yup.string(),
    lat: Yup.number()
        .required('Latitud es requerida'),
    lng: Yup.number()
        .required('Longitud es requerida'),
    total_attending_users: Yup.number(),
    total_register_users: Yup.number()
})


export const ViewEditEvent = () => {
    // Get the error handling functions
    const { errorType, handleApiError, clearError } = useErrorHandling();
    // Get the parameters from the URL
    const params = useParams();
    const { id } = params;
    // Get the query client
    const queryClient = useQueryClient();
    const navigation = useNavigate();

    // Define the state variables
    // States to control the modals
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    // Other states
    const [allName, setAllName] = useState('');
    const { userLogin } = useSelector((state: RootState) => state.auth);
    const [coordinates, setCoordinates] = useState<Coordinates>({ lat: 0, lng: 0})
    const [assistanceLevel, setAssistanceLevel] = useState<number>(0);

    // Get the data from the server
    const { data: event, isLoading, isError } = useQuery(['eventView', id],
        () => eventService.show(+id!)
    );

    // Define the states to control the form
    const [formDisabled, setFormDisabled] = useState(true);

    // Define the form methods
    const methods = useForm({
        resolver: yupResolver(updateEventSchema),
        defaultValues: {
            name: event?.data?.result?.name || '',
            code: event?.data?.result?.code || '',
            code_as400: event?.data?.result?.code_as400 || '',
            start_date: event?.data?.result?.start_date || '',
            end_date: event?.data?.result?.end_date || '',
            created_at: event?.data?.result?.created_at || '',
            event_society_id: event?.data?.result?.event_society_id,
            status: event?.data?.result?.status?.toString() || '',
            website_status: event?.data?.result?.website_status?.toString() || '',
            url: event?.data?.result?.content_event_location?.url || '',
            email: event?.data?.result?.content_event_location?.contact || '',
            address: event?.data?.result?.content_event_location?.address || '',
            reference: event?.data?.result?.content_event_location?.reference || '',
            lat: event?.data?.result?.content_event_location?.coordinates.lat || 0,
            lng: event?.data?.result?.content_event_location?.coordinates.lng || 0,
            total_attending_users: event?.data?.result?.total_attending_users || 0,
            total_register_users: event?.data?.result?.total_register_users || 0,
        }
    });

    // Get the form methods
    const { handleSubmit, reset, setValue } = methods;

    // Get the user data
    const getDatesInRange = (content_event_opening: ContentEventOpening[]) => {
        // Set the dates in the form
        const dates: ScheduleForm[] = [];
        // Get the dates in the range
        content_event_opening.forEach(eventOpening => (
            dates.push({
                ...eventOpening,
                date_label: getDateLabel(startOfDay(parseISO(eventOpening.date))),
                hour_init: eventOpening.hour_init.slice(0, -3) || '00:00',
                hour_end: eventOpening.hour_end.slice(0, -3) || '00:00',
            })
        ));
        // Set the dates in the form
        setValue('content_event_opening', dates);
    }

    // Set the data in the form
    useEffect(() => {
        // Set the data in the form
        if (!isLoading && event) {
            // Set the data in the form
            const lat =  event.data.result.content_event_location?.coordinates.lat || 0;
            const lng = event.data.result.content_event_location?.coordinates.lng || 0;
            setCoordinates({lat, lng});
            setAssistanceLevel(Number(event.data.result.assistance_level));
            setValue('name', event.data.result.name);
            setValue('code', event.data.result.code);
            setValue('code_as400', event.data.result.code_as400 || '');
            setValue('start_date', event.data.result.start_date);
            setValue('end_date', event.data.result.end_date);
            setValue('created_at', format(parseISO(event.data.result.created_at), 'dd/MM/yyyy'));
            setValue('event_society_id', event.data.result.event_society_id);
            setValue('status', event.data.result.status.toString());
            setAllName(event.data.result.user_person.firstname + ' ' + event.data.result.user_person.lastname);
            getDatesInRange(event.data.result.content_event_opening);
            setValue('url', event.data.result.content_event_location?.url);
            setValue('email', event.data.result.content_event_location?.contact);
            setValue('website_status', event.data.result.website_status.toString());
            setValue('lat', lat);
            setValue('lng', lng);
            setValue('address', event.data.result.content_event_location?.address);
            setValue('reference', event.data.result.content_event_location?.reference);
            setValue('total_attending_users', event.data.result.total_attending_users);
            setValue('total_register_users', event.data.result.total_register_users);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, isLoading]);

    // Define the mutation to update the user
    const userMutation = useMutation({
        mutationFn: eventService.update,
        onSuccess: () => {
            setShowModalSuccess(false)
            toast.success('El evento fue guardado con éxito.', {
                position: "bottom-right",
                autoClose: 5000, // Tiempo de cierre automático en milisegundos
                hideProgressBar: true, // Mostrar barra de progreso
                closeOnClick: true, // Cerrar al hacer clic
                draggable: true, // Arrastrable
                progress: undefined, // Personalizar la barra de progreso
                icon: <IconCheckCircle />,
                bodyClassName: "custom-toast-success"
            });
            // Invalidate and refetch
            queryClient.invalidateQueries('eventList');
            queryClient.invalidateQueries('eventView');
            navigation('/eventos/listar');
        },
        onError: (e: any) => {
            handleApiError(e)
        }
    })

    // Define the function to submit the form
    const onSubmitBack = () => {
        navigation(-1);
        reset();
    };

    // Define the function to submit the form
    const onSubmit = (data: any) => {
        // Get the data from the form
        const { email, url, address, reference, lat, lng, code_as400, created_at, ...rest } = data;
        // Set the data to send
        const newData = {...rest};
        // Add the location data
        newData.content_event_location = {
            coordinates: {lat, lng},
            contact: email,
            url,
            address,
            reference
        };
        // Add the opening hours
        newData.content_event_opening = [];
        data.content_event_opening.forEach((item: any) => {
            const {date_label, ...rest} = item;
            newData.content_event_opening.push(rest);
        });
        // Execute the mutation
        userMutation.mutate({ ...newData, id: id });
    };

    // Return the component when the data is loading
    if (isLoading) {
        return <div>Cargando...</div>
    };
    // Return the component when there is an error
    if (isError) {
        return <div>Error al cargar los usuarios</div>
    };

    // Define the function to handle the edit
    const onHandleEdit = () => {
        setFormDisabled(false);
    };


    return (
        <div className="">
            <div className="d-flex justify-content-between align-items-center">
                <MainTitle
                    disabled={formDisabled}
                    title='Ver detalle de evento'
                    editTitle='Editar evento'
                    icon />
                {
                    <div className='d-flex align-items-center'>
                        <button

                            type='button'
                            className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                            <IconHeart stroke="#00A160" />
                        </button>
                        <button

                            type='button'
                            className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                            <IconShoppingBag stroke="#00A160" />
                        </button>
                        <button

                            type='button'
                            className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                            <IconUsers stroke="#00A160" />
                        </button>
                        {(formDisabled && (userLogin?.capability_permissions.includes('content_event_update') || userLogin?.admin === 1)) && <button
                            onClick={onHandleEdit}
                            type='button'
                            className="btn btn-primary btn-outline-primary me-2 d-flex align-items-center gap-3">
                            <IconPencil />
                            Editar evento
                        </button>
                        }
                    </div>

                }

            </div>

            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x ms-3  mt-5 mb-7 fs-6">
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_4"
                    >
                        Información general
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_5"
                    >
                        Información adicional
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_users"
                    >
                        Usuarios asignados
                    </a>
                </li>
                {formDisabled && <li className="nav-item">
                    <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_event_page"
                    >
                        Página del evento
                    </a>
                </li>}

            </ul>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="tab-content" id="myTabContent">
                        <div
                            className="tab-pane fade show active"
                            id="kt_tab_pane_4"
                            role="tabpanel"
                        >
                            <GeneralInformationUpdate
                                hasBeenSync={event?.data.result.has_been_synced}
                                name={allName}
                                formDisabled={formDisabled}
                                assistanceLevel={assistanceLevel}
                            />
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
                            <AdditionalInformationUpdate formDisabled={formDisabled} coordinates={coordinates} />
                        </div>
                        <div className="tab-pane fade" id="kt_tab_pane_users" role="tabpanel">
                            <AssignedUsers />
                        </div>
                        {formDisabled && <div className="tab-pane fade" id="kt_tab_pane_event_page" role="tabpanel">
                            <EventPageSettings url={event?.data?.result?.content_event_location?.url || ''}/>
                        </div>}
                    </div>

                    {!formDisabled && (
                        <div className="container mt-15 mb-5">
                            <div className="row justify-content-center">
                                <div className="col-auto">

                                    <button
                                        type='button'
                                        onClick={() => setShowModalCancel(true)}
                                        className="btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/login/exit.svg')} />
                                        Cancelar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button
                                        onClick={() => {
                                            const startDate = methods.getValues().start_date;
                                            const endDate = methods.getValues().end_date;
                                            const data = methods.getValues();
                                            const lat = methods.getValues().lat;
                                            const lng = methods.getValues().lng;

                                            delete data.code_as400;
                                            delete data.created_at;
                                            delete data.address;
                                            delete data.reference;
                                            delete data.content_event_opening;

                                            const isValid = Object.values(data).map((e) => {
                                                if ( e === null || e === undefined || e === '' || (Array.isArray(e) && e.length === 0))
                                                    return false;
                                                return true;
                                            })
                                            .includes(false)

                                            if (
                                                isValid ||
                                                !endDate ||
                                                !startDate || !lat || !lng
                                            ) {
                                                setShowModalAlert(true)
                                            } else {
                                                setShowModalSuccess(true)
                                            }
                                        }}
                                        type='button'
                                        className="btn btn-primary me-2  d-flex align-items-center gap-3">
                                        <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
                                        Guardar cambios
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                </form>
            </FormProvider>
            <ModalSuccess onSubmit={handleSubmit(onSubmit)} show={showModalSuccess} onHide={() => setShowModalSuccess(false)} />
            <ModalConfirmation
                icon={<IconCancelCircle />}
                title='¿Seguro que deseas cancelar sin haber guardado los cambios?'
                textButton='Sí, cancelar'
                textButtonCancel='No, continuar'
                show={showModalCancel}
                onHide={() => setShowModalCancel(false)}
                onClick={onSubmitBack}
            />
            <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} />
            {errorType && (
                <ModalErrors errorType={errorType} onClose={clearError} />
            )}

        </div>
    )
}
