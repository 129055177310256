import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebar/sidebarSlice';
import authReducer from './auth/authSlice';
import passwordReducer from './password/passwordSlice';
import managementFileReducer  from './managementFile/managementFileSlice';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Este import garantiza el uso del almacenamiento local del navegador
import wishlistconfirmSlice from './wishlish/wishlistconfirmSlice';
import wishlistDataSlice from './wishlish/wishlistDataSlice';

const sidebarConfig = {
  key: 'sidebar', // La clave utilizada para el almacenamiento local
  storage, // El tipo de almacenamiento (en este caso, almacenamiento local)
};

const authtConfig = {
  key: 'auth', // La clave utilizada para el almacenamiento local
  storage, // El tipo de almacenamiento (en este caso, almacenamiento local)
};


const persistedReducer = persistReducer(sidebarConfig, sidebarReducer);
const persistedUserReducer = persistReducer(authtConfig, authReducer);

const store = configureStore({
  reducer: {
    sidebar: persistedReducer,
    auth: persistedUserReducer,
    wishlistconfirm: wishlistconfirmSlice,
    wishlistData: wishlistDataSlice,
    password: passwordReducer,
    managementFile: managementFileReducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store); // Crea un persistor para almacenar el estado

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;