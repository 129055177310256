import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'

import clsx from 'clsx'
import { Controller, useFormContext } from 'react-hook-form'
import { IconExit } from '../../../../../commons/icons'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../store/store'
import { Providers } from '../seccion/Providers'
import { Category } from '../seccion/Category'
import { Ruc } from '../seccion/Ruc'
import { Brand } from '../seccion/Brand'
import { useAuth } from '../../../auth/core/Auth'
import { useLocation } from 'react-router-dom'

export const FilterOffCanvas = ({ filter, setFilter, show, handleClose, handleShow, clearStatus }) => {

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const { userLogin } = useSelector((state: RootState) => state.auth)

  const permissionsData = userLogin?.entity_suppliers || []
  const categoryData = userLogin?.taxonomy_categories || []

  const arregloStatus = filter?.status?.split(',').map(Number);
  const filterStatus = arregloStatus ? arregloStatus?.slice(1) : [];

  const [isFocused, setIsFocused] = useState<string>('')
  const [status, setStatus] = useState<number[]>(filterStatus);
  const { eventSelectedCtx } = useAuth()

  const {
    clearErrors,
    reset,
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, touchedFields, isSubmitting, isValid },
    setError,
    getValues
  } = useFormContext()




  const handleApplyFilter = (data) => {
    console.log(data);

    const formatUnitMeasure = data?.unit_measure?.map((option) => option.label).join(',')
    const formatStatus = status.join(',')

    setFilter({ ...data, unit_measure: formatUnitMeasure, status: formatStatus, content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx }, (xddd) => {
      console.log(xddd);
    })
    handleClose()

  }

  const handleResetFilter = () => {
    reset({
      sku: '',
      name: '',
      ruc: '',
      unit_measure: [],
      entity_supplier_id: '',
      event_code: '',
      taxonomy_category_id: '',
      status: "",
      content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx,
    })
    setStatus([])
    setFilter({ content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx })
    handleClose()
  }

  const handleStatusCheckboxChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (e.target.checked) {
      // Agrega el valor a la lista si el checkbox se marca
      setStatus([...status, value]);
    } else {
      // Elimina el valor de la lista si el checkbox se desmarca
      setStatus(status.filter((item) => item !== value));
    }
  };

  useEffect(() => {

    setStatus([])

  }, [clearStatus])


  useEffect(() => {
    handleApplyFilter({
      sku: '',
      name: '',
      ruc: '',
      unit_measure: [],
      entity_supplier_id: '',
      event_code: code,
      taxonomy_category_id: '',
      status: "",
      content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx,
    })
    // console.log(getValues());

  }, [code])

  const onSubmit = (data) => {
    const selectedValues = data.unit_measure?.map((option) => option.value).join(',')
    const selectedCheckboxes = Object.keys(data).filter(
      (key) => data[key] === true
    );
    // Aquí puedes enviar selectedValues al backend, que contendrá los valores seleccionados separados por comas.
    console.log(selectedCheckboxes)
  }

  return (
    <>
      <Button variant='primary' onClick={handleShow} className='me-2'>
        <IconExit />
      </Button>
      <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header className='bg-white' closeButton>
          <Offcanvas.Title>Filtros</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='bg-gray-200'>
          <div className='pb-20'>
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>SKU</label>
              <input
                placeholder='Ingresar SKU'
                {...register('sku')}
                // defaultValue={result?.data?.result?.document_number}
                className={clsx(
                  'form-control custom-input',
                  /*  { 'bg-transparent': !formDisabled }, */
                  { 'is-invalid': errors.sku },
                  {
                    'is-valid': isFocused === 'sku' && !errors.sku,
                  }
                )}
                onFocus={() => setIsFocused('sku')}
                type='text'
                autoComplete='off'
              />
            </div>

            {eventSelectedCtx == 0 && (<div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Nombre del SKU</label>
              <input
                placeholder='Ingresar nombre del sku'
                {...register('name')}
                // defaultValue={result?.data?.result?.document_number}
                className={clsx(
                  'form-control custom-input',
                  /*  { 'bg-transparent': !formDisabled }, */
                  { 'is-invalid': errors.name },
                  {
                    'is-valid': isFocused === 'name' && !errors.name,
                  }
                )}
                onFocus={() => setIsFocused('name')}
                type='text'
                autoComplete='off'
              />
            </div>)}
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Unidad de medida</label>
              <Controller
                name='unit_measure'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder=''
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: '#00a16080',
                      },
                    })}
                    {...field}
                    isMulti
                    options={userLogin?.unit_measures?.map((e) => {
                      return {
                        value: e.id,
                        label: e.name,
                      }
                    })}
                  />
                )}
              />
            </div>
            {/*  <div className='fv-row col-12 mb-7'>
              <Brand data={permissionsData} filterSuppliers={filter?.entity_supplier_id} />
            </div>
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Linea</label>
              <input
                placeholder='Ingresar Linea'
                {...register('line')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.line },
                  {
                    'is-valid': isFocused === 'line' && !errors.line,
                  }
                )}
                onFocus={() => setIsFocused('line')}
                type='text'
                autoComplete='off'
              />
            </div>
            <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Empaque Master</label>
              <input
                placeholder='Ingresar Empaque Master'
                {...register('master_package')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.master_package },
                  {
                    'is-valid': isFocused === 'master_package' && !errors.master_package,
                  }
                )}
                onFocus={() => setIsFocused('master_package')}
                type='text'
                autoComplete='off'
              />
            </div> */}
            <div className='fv-row col-12 mb-7'>
              <Ruc data={permissionsData} filterSuppliers={filter?.ruc} />
            </div>

            {/*    <div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>RUC</label>
              <input
                placeholder='Ingresar RUC'
                {...register('ruc')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.ruc },
                  {
                    'is-valid': isFocused === 'ruc' && !errors.ruc,
                  }
                )}
                onFocus={() => setIsFocused('ruc')}
                type='text'
                autoComplete='off'
              />
            </div> */}


            <div className='fv-row col-12 mb-7'>
              <Providers data={permissionsData} filterSuppliers={filter?.entity_supplier_id} />
            </div>
            <div className='fv-row col-12 mb-7'>
              <Category data={categoryData} filterCategories={filter?.taxonomy_category_id} />
            </div>

            {eventSelectedCtx == 0 && (<div className='fv-row col-12 mb-7'>
              <label className='form-label fs-6 text-dark'>Código del evento</label>
              <input
                placeholder='Ingresar código del evento'
                {...register('event_code')}
                className={clsx(
                  'form-control custom-input',
                  { 'is-invalid': errors.event_code },
                  {
                    'is-valid': isFocused === 'event_code' && !errors.event_code,
                  }
                )}
                onFocus={() => setIsFocused('event_code')}
                type='text'
                autoComplete='off'
              />
            </div>)}

            <div className='fv-row col-12 mb-7'>
              <div className='form-label fs-6 text-dark'>Estado</div>
              <div className="d-flex align-items-center gap-4">
                <div className='form-check'>
                  <input
                    type="checkbox"
                    value="1"
                    className='form-check-input'
                    checked={status.includes(1)}
                    onChange={handleStatusCheckboxChange}
                  />
                  <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                    Activo
                  </label>
                </div>
                <div className='form-check'>
                  <input
                    type="checkbox"
                    value="2"
                    className='form-check-input'
                    checked={status.includes(2)}
                    onChange={handleStatusCheckboxChange}
                  />
                  <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                    Inactivo
                  </label>
                </div>
              </div>
              {/*  <CheckboxGroup options={options} onChange={handleCheckboxGroupChange} /> */}

            </div>


            {/*  <div className='fv-row col-12 mb-7'>
                            <label className='form-label fs-6 text-dark'>Estado</label>
                            <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        placeholder=""
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: '#00a16080',
                                            },
                                        })}
                                        {...field}
                                        isMulti
                                        options={[
                                            { value: '1', label: 'Activo' },
                                            { value: '2', label: 'Inactivo' },
                                        ]}
                                    />
                                )}
                            />
                        </div > */}
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: '20px',
              background: 'white',
            }}
          >
            {/* Contenido del footer */}
            <div className='d-flex '>
              <div className=' flex-grow-1 '></div>
              <div className=''>
                <button
                  type='button'
                  className='btn btn-outline btn-outline-primary btn-sm  me-4'
                  onClick={handleResetFilter}
                >
                  Limpiar
                </button>
                <button
                  type='button'
                  className='btn btn-primary  btn-sm'
                  onClick={handleSubmit(handleApplyFilter)}
                >
                  Aplicar
                </button>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
