import {useSelector} from 'react-redux'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {MainTitle} from '../../../../commons/components/Title/MainTitle'
import {useMutation, useQueryClient} from 'react-query'
import giftService from '../../../../services/gifts'
import {useEffect, useState} from 'react'
import FormElemenWrapper from '../../../../commons/components/formElemenWrapper/FormElemenWrapper'
import {IconAlertCircle, IconSearch2, IconGift, IconCheckCircle} from '../../../../commons/icons'
import clsx from 'clsx'
import {useAuth} from '../../auth'
import {RootState} from '../../../../store/store'
import Toast from '../../wishlist/components/Toast'
import {
  FilterToGetGifts,
  GiftSelected,
} from '../../../../commons/interfaces/gifts/giftList.interface'
import TableGiftsWithCheckbox from '../components/tables/TableGiftsWithCheckbox'
import {ModalSuccess} from '../../clients/components/modals/ModalSuccess'
import { toast } from 'react-toastify'
import { useErrorHandling } from '../../../../commons/hooks/useErrorHandling'
import { ModalErrors } from '../../../../commons/components/modals/ModalErrors'
import { useNavigate, useParams } from 'react-router-dom'

// Define the schema for the form
const searchGiftsSchema = Yup.object().shape({
  entity_customer_document_type: Yup.string().required('Este campo es requerido'),
  entity_customer_document_number: Yup.string().test(
    'entity_customer_document_number',
    'El número de documento es inválido',
    (value, context) => {
      const {entity_customer_document_type} = context.parent
      if (entity_customer_document_type === 'DNI' && value) {
        return value?.length === 8 && /^[0-9]+$/.test(value)
      } else if (entity_customer_document_type === 'CE' && value) {
        return value?.length === 9 && /^[0-9]+$/.test(value)
      }
      return true
    }
  ),
  entity_customer_id: Yup.string().test(
    'entity_customer_id',
    'El ID del cliente es inválido',
    (value, context) => {
      const {entity_customer_document_number} = context.parent
      if (!value && !entity_customer_document_number) {
        return false
      }
      return true
    }
  ),
  content_event_id: Yup.string().required('Evento es requerido'),
})

const SearchCustomerToGifts = () => {
  // Get the navigate method
  const navigate = useNavigate();
  // Get the query client
  const queryClient = useQueryClient();
  // Get the error handling methods
  const {errorType, handleApiError, clearError} = useErrorHandling();
  // Get the event selected
  const {eventSelectedCtx} = useAuth();
  // Get the user login
  const {userLogin} = useSelector((state: RootState) => state.auth);
  // Define the variables to use
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gifts, setGifts] = useState<GiftSelected[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  //Params
  const params = useParams();
  const {contentEventId, entityCustomerId} = params;

  // Define the form methods
  const methods = useForm({
    resolver: yupResolver(searchGiftsSchema),
    defaultValues: {
      entity_customer_document_type: 'DNI',
      entity_customer_document_number: '',
      entity_customer_id: '',
      content_event_id: eventSelectedCtx ? eventSelectedCtx.id : userLogin?.active_events[0]?.id,
    },
    mode: 'onChange',
  })

  // Get the form methods
  const {
    handleSubmit,
    setValue,
    register,
    formState: {errors, isDirty, isValid},
  } = methods

  const searchMutation = useMutation({
    mutationFn: giftService.getGiftsByEventAndCustomer,
    onSuccess: (data) => {
      if (data.status === 200 && data.data.code === 200) {
        const customer =
          data.data.result?.data && data.data.result?.data.length > 0
            ? data.data.result?.data[0].generated_order?.purchase_order?.entity_customer
            : null
        const event = data.data.result?.data && data.data.result?.data && data.data.result?.data.length > 0
            ? data.data.result?.data[0].content_event
            : null
        setGifts(data.data.result?.data?.map((item: GiftSelected) => ({...item, selected: 1})))
        if (customer && event) {
          setIsError(false)
          setLoading(false)
          setValue('entity_customer_document_type', customer.document_type || '')
          setValue('entity_customer_document_number', customer.document_number || '')
          setValue('entity_customer_id', customer.code_as400 || '')
          setValue('content_event_id', event.id)
        } else showError()
      } else showError()
    },
    onError: (e: any) => {
      showError()
    },
  })

  const massiveMutation = useMutation({
    mutationFn: giftService.redeemGifts,
    onSuccess: () => {
      toast.success('El canje fue realizado con éxito.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      });
      queryClient.invalidateQueries(['giftList'])
      navigate('/regalos/busqueda')
    },
    onError: (error: any) => {
      handleApiError(error);
    },
  })

  const showError = () => {
    setIsError(true)
    setLoading(false)
  }

  // Define the function to submit the form
  const onFilter = (data: FilterToGetGifts) => {
    if (data.entity_customer_id) {
      searchMutation.mutate({
        content_event_id: data.content_event_id,
        entity_customer_id: data.entity_customer_id,
      })
    } else {
      searchMutation.mutate({
        content_event_id: data.content_event_id,
        entity_customer_document_type: data.entity_customer_document_type,
        entity_customer_document_number: data.entity_customer_document_number,
      })
    }
  }

  const redeemGifts = () => {
    setShowConfirmationModal(false);
    const idsOfSelectedGifts = gifts.filter((gift) => gift.selected === 1).map((gift) => gift.id);
    massiveMutation.mutate({ids: idsOfSelectedGifts, state: 'redeemed'});
  }

  useEffect(() => {
    if (contentEventId && entityCustomerId) {
      searchMutation.mutate({
        content_event_id: contentEventId,
        entity_customer_id: entityCustomerId,
      });
      setDisabledForm(true);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [])

  return (
    <div className=''>
      <div className='d-flex justify-content-between align-items-center'>
        <MainTitle title='Buscar cliente' editTitle='Buscar cliente' icon />
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onFilter)} className='row mt-5'>
          <FormElemenWrapper label='Tipo de documento' required>
            <select
              className='form-select'
              aria-label='Disabled select example'
              {...register('entity_customer_document_type')}
              disabled={disabledForm}
            >
              <option value='DNI'>DNI</option>
              <option value='CE'>Carnet de extranjería</option>
              <option value='PA'>Pasaporte</option>
              <option value='RUC'>RUC</option>
            </select>
          </FormElemenWrapper>
          <FormElemenWrapper label='Número de documento'>
            <input
              className='form-control'
              type='text'
              {...register('entity_customer_document_number')}
              disabled={disabledForm}
            />
          </FormElemenWrapper>
          <div className='col-12 mb-5'>
            <span className='text-gray-400 fw-bold'>o intenta con:</span>
          </div>
          <FormElemenWrapper label='ID del cliente'>
            <input
              className='form-control'
              type='text'
              {...register('entity_customer_id')}
              disabled={disabledForm}
            />
          </FormElemenWrapper>
          <FormElemenWrapper label='Evento' required>
            <select
              id='select_event'
              {...register('content_event_id')}
              required
              className={clsx('form-select', {
                'is-invalid': errors.content_event_id,
              })}
              disabled={disabledForm}
            >
              {eventSelectedCtx === 0
                ? userLogin?.active_events?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))
                : userLogin?.active_events
                    ?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                    .filter((item) => Number(item.key) === eventSelectedCtx)}
            </select>
            {errors.content_event_id && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.content_event_id.message as string}
                </span>
              </div>
            )}
          </FormElemenWrapper>
          {isError && (
            <div className='d-flex justify-content-center mt-5 mt-10 mb-5'>
              <Toast
                title='Cliente no registrado y/o inactivo.'
                type='warning'
                icon={<IconAlertCircle width={24} height={24} />}
              />
            </div>
          )}
          {!disabledForm && (
            <div className='col-12'>
              <button
                type='submit'
                disabled={!isDirty || !isValid || loading}
                id='kt_search_submit'
                className={`btn ${!isDirty || !isValid ? 'btn-disabled' : 'btn-primary'}`}
              >
                {!loading && (
                  <>
                    <IconSearch2
                      fill={!isDirty || !isValid ? '#BDBDBD' : 'white'}
                      width={20}
                      height={20}
                    />
                    <span className='indicator-label'> Buscar cliente</span>
                  </>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Cargando...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </form>
      </FormProvider>
      {(gifts.length > 0 &&
        !loading &&
        !isError &&
        (userLogin?.capability_permissions.includes('deal_gift_view') ||
        userLogin?.admin === 1)) && (
        <>
        <div className='mt-6'>
          <div className='overflow-auto' style={{maxHeight: '500px'}}>
            <TableGiftsWithCheckbox giftsTable={gifts} setGiftsTable={setGifts} />
          </div>
        </div>
        <div className='mt-6 col-12'>
          <button
            type='button'
            className='btn btn-primary d-flex gap-2'
            onClick={() => setShowConfirmationModal(true)}
          >
            <IconGift />
            Canjear regalos
          </button>
        </div>
        </>
      )}
      {/* The ModalErrors component is used to show an error message. */}
      {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
      {/* Render the modal for request approval */}
      <ModalSuccess
        onSubmit={redeemGifts}
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
      />
    </div>
  )
}

export default SearchCustomerToGifts
