export interface IWishListList {
    code: number
    status: string
    message: string
    result: Result
  }
  
  export interface Result {
    data: Daum[]
    links: Links
    meta: Meta
  }
  
  export interface Daum {
    id: number
    document_number: string
    document_type: string
    firstname: string
    lastname: string
    middlename: string
    content_event_id: number
    code: string
    content_products_count: number
    content_event: ContentEvent
    pin?: string | null
  }
  
  export interface ContentEvent {
    id: number
    name: string
    code: string
    code_as400: string
    start_date: string
    end_date: string
    status: number
  }
  
  export interface Links {
    first: string
    last: string
    prev: any
    next: string
  }
  
  export interface Meta {
    current_page: number
    from: number
    last_page: number
    links: Link[]
    path: string
    per_page: number
    to: number
    total: number
  }
  
  export interface Link {
    url?: string
    label: string
    active: boolean
  }
  
  export interface Root {
    code: number
    status: string
    message: string
    result: ResultWishDetailList[]
  }
  
  export interface ResultWishDetailList {
    id: number
    sku: string
    name: string
    code_as400: string
    unit_measure: string
    line: string
    brand: string
    master_package: string
    conversion_unit: number
    price: any
    content_event_id: number
    entity_supplier_id: number
    taxonomy_category_id: number
    unit_measure_id: number
    status: number
    content_event: ContentEvent
    taxonomy_category: TaxonomyCategory
    entity_customer: EntityCustomer
    quantity: number
    selected:number
  }
  
  export interface ContentEvent {
    id: number
    name: string
    status: number
  }
  
  export interface TaxonomyCategory {
    id: number
    name: string
  }
  
  export interface EntityCustomer {
    id: number
    code: string
  }
  

  export const datajson ={
    "code": 200,
    "status": "success",
    "message": "Ok",
    "result": [
        {
            "id": 3,
            "sku": "74",
            "name": "FOLDER OF T.T C/F ROSADO ARTESCO 10320002",
            "code_as400": "59",
            "unit_measure": "UND",
            "line": "ARTESCO - FOLDER",
            "brand": "ARTESCO",
            "master_package": "CJX100",
            "conversion_unit": 100,
            "price": null,
            "content_event_id": 1,
            "entity_supplier_id": 1,
            "taxonomy_category_id": 1,
            "unit_measure_id": 1,
            "status": 1,
            "entity_supplier": {
                "id": 1,
                "ruc": "20100067324",
                "business_name": "ARTESCO S.A.",
                "status": 1
            },
            "content_event": {
                "id": 1,
                "name": "Campaña Navideña",
                "status": 1
            },
            "taxonomy_category": {
                "id": 1,
                "name": "FOLDERS Y CARPETAS ESCOLARES"
            },
            "entity_customer": {
                "id": 10,
                "code": "5d989b0305a45516c514c3ee1321fc74"
            },
            "quantity": 4,
            "selected": 0
        },
        {
            "id": 2,
            "sku": "55",
            "name": "AGUJA PUNTA ROMA PLASTICO X2 DRAGON",
            "code_as400": "59",
            "unit_measure": "UND",
            "line": "IMPORTADO MANUALID DRAGON",
            "brand": "S/M",
            "master_package": "BLS100",
            "conversion_unit": 100,
            "price": null,
            "content_event_id": 1,
            "entity_supplier_id": 2,
            "taxonomy_category_id": 2,
            "unit_measure_id": 1,
            "status": 1,
            "entity_supplier": {
                "id": 2,
                "ruc": "20611113855",
                "business_name": "INDUSOL GLOBAL S.A.C.",
                "status": 1
            },
            "content_event": {
                "id": 1,
                "name": "Campaña Navideña",
                "status": 1
            },
            "taxonomy_category": {
                "id": 2,
                "name": "MANUALIDADES"
            },
            "entity_customer": {
                "id": 10,
                "code": "5d989b0305a45516c514c3ee1321fc74"
            },
            "quantity": 8,
            "selected": 1
        },
        {
            "id": 6,
            "sku": "1089",
            "name": "CARTON DUPLEX 282GR 70X100",
            "code_as400": "59",
            "unit_measure": "PQX100",
            "line": "CONVERSIONES TAI LOY- HO",
            "brand": "S/M",
            "master_package": "PQX100",
            "conversion_unit": 1,
            "price": null,
            "content_event_id": 1,
            "entity_supplier_id": 4,
            "taxonomy_category_id": 3,
            "unit_measure_id": 2,
            "status": 1,
            "entity_supplier": {
                "id": 4,
                "ruc": "20516858223",
                "business_name": "SAGITARIO INVERSIONES Y SERVICIOS S.A.C.",
                "status": 1
            },
            "content_event": {
                "id": 1,
                "name": "Campaña Navideña",
                "status": 1
            },
            "taxonomy_category": {
                "id": 3,
                "name": "PAPELES Y CARTONES"
            },
            "entity_customer": {
                "id": 10,
                "code": "5d989b0305a45516c514c3ee1321fc74"
            },
            "quantity": 7,
            "selected": 0
        }
    ]
}