import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {MainTitle} from '../../../../commons/components/Title/MainTitle'
import {toast} from 'react-toastify'
import {ModalErrors} from '../../../../commons/components/modals/ModalErrors'
import {useSelector} from 'react-redux'
import {useMutation, useQueryClient, useQuery} from 'react-query'
import {useErrorHandling} from '../../../../commons/hooks/useErrorHandling'
import {IconCheckCircle, IconPencil} from '../../../../commons/icons'
import eventMapService from '../../../../services/event/eventMapService'
import {RootState} from '../../../../store/store'
import {FileUploaded} from '../../../../commons/interfaces/managementFile/managementFileCreate'
import InputUploadFiles from '../../../../_metronic/layout/components/input/InputUploadFiles'
import FormElemenWrapper from '../../../../commons/components/formElemenWrapper/FormElemenWrapper'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Field, Form, Formik} from 'formik'
import {ModalAlert} from './components/modals/ModalAlert'
import eventSupplierService from '../../../../services/event/eventSupplierService'
import {ModalSuccess} from '../../clients/components/modals/ModalSuccess'

/*
 * EventMapPage
 * This component is resposible for rendering the page of map of the event
 * It uses the useQuery, useMutation and useQueryClient hooks from react-query to make the requests to the API.
 * It uses the useSelector hook from react-redux to access the userLogin state.
 * It uses the useErrorHandling hook to handle the errors.
 */
const EventMapPage = () => {
  const params = useParams()
  const queryClient = useQueryClient()
  const {userLogin} = useSelector((state: RootState) => state.auth)
  const {errorType, handleApiError, clearError} = useErrorHandling()
  const [mapImage, setMapImage] = useState<FileUploaded | null>(null)
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [file, setFile] = useState<FileUploaded | null>(null);

  // The useParams hook returns an object with the params of the URL.
  // In this case, we are destructuring the id from the params object.
  const {id} = params

  // The useState hook is used to manage the formDisabled state.
  const [formDisabled, setFormDisabled] = useState(true)

  // The useQuery hook returns an object with the data, isLoading and isError properties.
  const {
    data: event,
    isLoading,
    isError,
  } = useQuery(['eventMapView', id], () => eventMapService.show(+id!))

  const {
    data: suppliers,
    isLoading: isLoadingSupplier,
    isError: isErrorSupplier,
  } = useQuery(['eventSupplierView', id], () => eventSupplierService.get(+id!))

  // The useMutation hook returns an object with the mutation function and the status of the mutation.
  const userMutation = useMutation({
    // The mutationFn is the function that makes the request to the API.
    mutationFn: eventMapService.update,
    // The onSuccess function is called when the mutation is successful.
    // It shows a success toast and navigates to the event detail page.
    // It also invalidates the query to update the data.
    onSuccess: () => {
      toast.success('Los cambios fueron guardados con éxito.', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <IconCheckCircle />,
        bodyClassName: 'custom-toast-success',
      })
      queryClient.invalidateQueries(['eventMapView', id])
      setFormDisabled(true)
    },
    // The onError function is called when the mutation has an error.
    onError: (error: any) => {
      handleApiError(error)
    },
  })

  const handleSumit = (values: {image: FileUploaded | null}) => {
    const file = values.image
    if (file) {
      setFile(file)
      setShowConfirmationModal(true)
    } else {
      setShowModalAlert(true)
    }
  }

  // The onSubmit function is called when the edit button is clicked.
  const onSubmit = () => {
    if (file) {
      const data = {
        content_event_id: Number(id),
        management_file_public: [file],
      }
      userMutation.mutate(data)
    } else {
      setShowModalAlert(true)
    }
  }

  const renderSuppliers = () => {
    if (isLoadingSupplier) {
      return <div>Cargando...</div>
    }

    // The isError property is used to show an error message.
    if (isErrorSupplier) {
      return <div>Error al cargar proveedores</div>
    }

    if (suppliers && suppliers.length > 0) {
      return (
        <>
          <span className='title-form fw-bold my-5'>Lista de proveedores</span>
          <div className='col-12 col-md-8 d-flex row'>
            {suppliers?.map((supplier) => (
              <span key={`supplier_${supplier.id}`} className='col-12 col-md-6 text-gray-400 my-3'>
                {supplier.business_name}
              </span>
            ))}
          </div>
        </>
      )
    }

    return
  }

  useEffect(() => {
    if (event?.data.result) {
      setMapImage(event.data.result)
    }
  }, [event])

  // The isLoading and isError properties are used to show a loading message or an error message.
  if (isLoading) {
    return <div>Cargando...</div>
  }

  // The isError property is used to show an error message.
  if (isError) {
    return <div>Error al cargar el mapa</div>
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-column'>
        <div className='d-flex justify-content-between align-items-center mb-6'>
          <MainTitle
            disabled={formDisabled}
            title='Mapa del evento'
            editTitle='Mapa del evento'
            icon
          />
          {/* The button is used to enable the form to edit the map. */}
          {formDisabled &&
            (userLogin?.capability_permissions.includes('content_event_update') ||
              userLogin?.admin === 1) && (
              <button
                onClick={() => setFormDisabled(false)}
                type='button'
                className='btn btn-primary btn-outline-primary me-2 d-flex align-items-center gap-3'
              >
                <IconPencil />
                Editar Mapa
              </button>
            )}
        </div>
        {/*Formik*/}
        <Formik enableReinitialize={true} initialValues={{image: mapImage}} onSubmit={handleSumit}>
          <Form>
            <div className='card p-5'>
              <div className='card-body'>
                <FormElemenWrapper label=''>
                  <Field name='image'>
                    {({field, form}) => (
                      <InputUploadFiles
                        required
                        id='image-map'
                        formDisabled={formDisabled}
                        file={field.value}
                        handleFunction={(value) => form.setFieldValue('image', value)}
                      />
                    )}
                  </Field>
                </FormElemenWrapper>
                <div className='d-flex justify-content-start'>
                  <button
                    type='submit'
                    className='btn btn-primary me-2  d-flex align-items-center gap-3'
                    disabled={formDisabled}
                  >
                    <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
                    Guardar cambios
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
        {renderSuppliers()}
        {/* The ModalErrors component is used to show an error message. */}
        {errorType && <ModalErrors errorType={errorType} onClose={clearError} />}
        {/* Render the modals for the alerts */}
        <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} />
        {/* Render the modal for request approval */}
        <ModalSuccess
          onSubmit={onSubmit}
          show={showConfirmationModal}
          onHide={() => setShowConfirmationModal(false)}
        />
      </div>
    </>
  )
}

export default EventMapPage
