import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { EntitySupplier } from '../../../../../commons/interfaces/auth/login'
import { useFormContext } from 'react-hook-form'
import { IconExit } from '../../../../../commons/icons'
import { useStateCallback } from '../../../../../commons/hooks/useStateCallback'

interface IProps {
  data: EntitySupplier[]
  suppliers?: any
  filterSuppliers?: any
}

export const Ruc = ({ data, suppliers, filterSuppliers }: IProps) => {

  const arreglo = filterSuppliers?.split(',').map(Number);

  const dataIdViewSuppliers = suppliers ? suppliers?.map((item) => item.id) : filterSuppliers ? arreglo : []
  const dataViewSuppliers = suppliers ? suppliers : []

  const {
    setValue,
  } = useFormContext()
  const [filterText, setFilterText] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const [selectedItems, setSelectedItems] = useStateCallback<any>(dataIdViewSuppliers)
  const [selectedItemsList, setSelectedItemsList] = useStateCallback<any>(dataViewSuppliers)

  useEffect(() => {
    // Verificar si todos los elementos han sido eliminados
    /* if (selectedItems.length === 0) {
      setSelectAll(false)
    } */
  }, [selectedItems])

  const handleFilterChange = (e) => {
    setFilterText(e.target.value)
  }

  const handleItemToggle = (id, name, ruc) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(
        selectedItems.filter((item) => item !== id),
        (newRoles) => {
          const newRolesSplit = newRoles?.join(',')
          setValue('ruc', newRolesSplit)
        }
      )
      setSelectedItemsList(selectedItemsList.filter((item) => item.id !== id))
    } else {
      setSelectedItems([...selectedItems, id], (newRoles) => {
        const newRolesSplit = newRoles?.join(',')
        setValue('ruc', newRolesSplit)
      })
      setSelectedItemsList([...selectedItemsList, { id, business_name: name, ruc }])
    }
  }

  const filteredData = data.filter((item) =>
    item.ruc.toLowerCase().includes(filterText.toLowerCase())
  )

  return (
    <>
      <div className='fv-row col-12'>
        <label className='form-label fs-6 text-dark'>RUC</label>
        <input

          type='text'
          placeholder='Buscar RUC'
          className={clsx('form-control')}
          value={filterText}
          onChange={handleFilterChange}
        />
        <div className='card card-stretch card-bordered bg-transparent p-4 mt-4 border-gray-300  h-250px overflow-auto'>

          {filteredData.map((item) => (
            <div className='form-check mb-2' key={item.id}>
              <input
                type='checkbox'
                className='form-check-input'
                checked={selectedItems.includes(item.id)}
                onChange={() => handleItemToggle(item.id, item.business_name, item.ruc)}
                value={item.id}
              />
              <label className='form-check-label text-black fw-light' htmlFor='flexCheckDefault'>
                {item.ruc}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
