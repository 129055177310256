/**
 * AdditionalInformation Component
 *
 * This component displays additional information related to an event, such as location, schedule, and contact details.
 *
 * @component
 * @param {boolean} formDisabled - Indicates whether the form is disabled or not.
 * @returns {JSX.Element} AdditionalInformation component.
 */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import {DatePicker} from '../../../../../../commons/components/datePicker/DatePicker';
import { format, startOfDay, endOfDay, addDays, parseISO } from 'date-fns';
import {getDateLabel} from '../../../../../../commons/helpers/general';
import {ScheduleForm} from '../../../../../../commons/interfaces/event/createEvent';
import {TimePicker} from '../../../../../../commons/components/datePicker/TimePicker';
import FormElemenWrapper from '../../../../../../commons/components/formElemenWrapper/FormElemenWrapper';
import MapComponent, { Coordinates } from '../maps/MapComponent';
import clsx from 'clsx';

interface IProps {
  formDisabled?: boolean
}
export const AdditionalInformation = ({formDisabled}: IProps) => {
  // Form context
  const {
    setValue,
    watch,
    register,
    formState: {errors, touchedFields},
  } = useFormContext();
  // Watch the start and end date
  const startDate = watch('start_date');
  const endDate = watch('end_date');
  // Watch the schedule
  const schedule = watch('content_event_opening');

  // Function to update the schedule
  const onChange = (date: string, key: string, value: string) => {
    const newSchedule = schedule?.map((item: ScheduleForm) => {
      if (item.date === date) {
        return {...item, [key]: value};
      }
      return item;
    });
    setValue('content_event_opening', newSchedule);
  };

  // Function to render the schedule
  const renderSchedule = () => {
    return schedule?.map((item: ScheduleForm, index: number) => (
      <div className='d-flex row mb-5' key={`schedule_${index}`}>
        <div className='col-12 col-md-5'>
          <input
            type='text'
            autoComplete='off'
            className='form-control bg-transparent'
            disabled
            value={item.date_label}
          />
        </div>
        <div className='d-flex row col-12 col-md-7 align-items-center justify-content-between'>
          <div className='col-12 col-md-5'>
            <TimePicker
              id={`hour_init_${item.date}`}
              handleChange={(value: string) => onChange(item.date, 'hour_init', value)}
              timeValue={item.hour_init}
              maxTime={item.hour_end}
            />
          </div>
          <div className='d-none d-md-flex col-md-2 justify-content-center'>-</div>
          <div className='col-12 col-md-5'>
            <TimePicker
              id={`hour_end_${item.date}`}
              handleChange={(value: string) => onChange(item.date, 'hour_end', value)}
              timeValue={item.hour_end}
              minTime={item.hour_init}
            />
          </div>
        </div>
      </div>
    ));
  };

  // Function to get the dates in the range
  const getDatesInRange = () => {
    setValue('content_event_opening', []);
    setTimeout(() => {
      const dates: ScheduleForm[] = [];
      let currentDate = startOfDay(parseISO(startDate));
      while (currentDate <= endOfDay(parseISO(endDate))) {
        const formattedDate = format(currentDate, 'yyyy-MM-dd');
        const dateLabel = getDateLabel(currentDate);
        dates.push({ date: formattedDate, date_label: dateLabel, hour_init: '00:00', hour_end: '23:59' });
        currentDate = addDays(currentDate, 1);
      };
      setValue('content_event_opening', dates);
    }, 100);
  };

  const handleSelectCoordinates = (coordinates: Coordinates) => {
    setValue('lat', coordinates.lat);
    setValue('lng', coordinates.lng);
  }

  // Watch the start and end date
  useEffect(() => {
    if (startDate && endDate) getDatesInRange()
    else setValue('content_event_opening', [])
  }, [startDate, endDate])

  return (
    <div className=''>
      <div className='row'>
        <div className='col-12 col-md-6 mb-6'>
          <input
            {...register('address')}
            className='form-control bg-transparent'
            type='text'
            autoComplete='off'
            placeholder='Dirección'
          />
        </div>
        <div className='col-12 col-md-6 mb-6'>
          <input
            {...register('reference')}
            className='form-control bg-transparent'
            type='text'
            autoComplete='off'
            placeholder='Referencia'
          />
        </div>
        <div className="col-12 col-md-8">
          <span className='required text-muted'>Seleccionar ubicación</span>
          <MapComponent selectCoordinates={handleSelectCoordinates}/>
          {(errors.lat || errors.lng) && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger' role='alert'>
                  {errors.lat && errors.lat.message as string}  {errors.lng && errors.lng.message as string}
                </span>
              </div>
            )}
        </div>
      </div>
      <div className='col-12 col-xl-8'>
        <div className='mt-6 mb-6 ms-md-4'>
          <h2 className='title-form fw-bold mb-5'>Días de atención</h2>
          <div className='row  mb-4 '>
            <DatePicker />
          </div>
        </div>
        {(startDate && endDate) && (
          <>
            <hr className='hr' />
            <div className='mt-6 ms-md-4'>
              <h2 className='title-form fw-bold mb-5'>Horario de atención</h2>
              {renderSchedule()}
            </div>
          </>
        )}
      </div>
      <div className='row'>
        <div className='col-12'>
          <hr className='hr' />
          <div className='mt-6 mb-6 ms-md-4'>
            <h2 className='title-form fw-bold mb-5'>Contacto</h2>
            <div className='row mb-4 '>
              <FormElemenWrapper label='Correo de contacto' required>
                <input
                  {...register('email')}
                  className={clsx(
                    'form-control',
                    {'bg-transparent': !formDisabled},
                    {'is-invalid': errors?.email},
                    {
                      'is-valid': touchedFields.email && !errors.email,
                    }
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{errors.email.message as string}</span>
                  </div>
                )}
              </FormElemenWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
