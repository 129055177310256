import React, { useEffect, useState } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'

import userPersonService from '../../../../services/userPerson'
/* import { TableListUsers } from '../components/tables/TableListUsers' */
import { useLocation, useNavigate } from 'react-router-dom'
import { usePaginator } from '../../../../commons/hooks/usePaginator'
import IconSync from '../../../../commons/icons/IconSync'
import IconFilter from '../../../../commons/icons/IconSearch'
import assortmentService from '../../../../services/assortment'
import { TableLitAssortments } from '../components/tables/TableLitAssortments'
import { FilterOffCanvas } from '../components/offCanvas/FilterOffCanvas'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import useModal from '../../../../commons/hooks/useModal'
import ModalSync from '../components/modals/ModalSync'
import { IAssortmentSync } from '../../../../commons/interfaces/assortment/AssortmentSync'
import { ModalConfirmation } from '../../../../commons/components/modals/ModalConfirmation'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useAuth } from '../../auth'
import { useStateCallback } from '../../../../commons/hooks/useStateCallback'
import SearchFilter from '../../../../commons/components/search/SearchFilter'


const pageSizes = [10, 20, 50]

export const ListAssortments = () => {

  const methods = useForm({
    defaultValues: {
      status: [{ value: '1', label: 'Activo' }],
      sku: '',
      name: '',
      ruc: '',
      unit_measure: [],
      entity_supplier_id: '',
      event_code: '',
      taxonomy_category_id: '',
      content_event_id: '',
    },
  })
  const { handleSubmit, reset, formState, setError, getValues, watch } = methods;
  //const data = methods.getValues()

  const { handleClose, handleShow, show } = useModal()
  const { handleClose: handleCloseConfirmation, handleShow: handleShowConfirmation, show: showConfirmation } = useModal()
  const { handleClose: handleCloseSync, handleShow: handleShowSync, show: showSync } = useModal()
  const [loading, setLoading] = useState(false)
  const [dataSync, setDataSync] = useState<IAssortmentSync>()
  const { eventSelectedCtx } = useAuth()
  const [filter, setFilter] = useStateCallback(eventSelectedCtx == 0 ? {} : { content_event_id: eventSelectedCtx })
  const [search, setSearch] = useState<string>('')
  const { userLogin } = useSelector((state: RootState) => state.auth)
  const [notResult, setNotResult] = useState(false)
  const [notResultFilter, setNotResultFilter] = useState(false)
  const [clearStatus, setClearStatus] = useState([])

  const {
    limit,
    setPage,
    paginationComponent,
    query: { data: users, refetch, isRefetching },
  } = usePaginator({
    initPage: 1,
    initLimit: pageSizes.at(0)!,
    pageSizes,
    search,
    filters: filter,
    paginatorOptions: {
      queryKey: ['assortmentList'],
      fetch: assortmentService.list,
    },
  })

  const queryClient = useQueryClient();

  const syncData = useMutation(assortmentService.sync, {
    onSuccess: async () => {
      await refetch(); // Vuelve a solicitar los datos
      queryClient.invalidateQueries('assortmentList'); // Actualiza la lista
    },
    onError: (error) => {
      console.error('Error al sincronizar datos:', error);
    },
  });

  const handleSync = async () => {
    handleShowSync();
    setLoading(true);
    console.log('Antes de la llamada a syncData.mutate');

    const res = await syncData.mutateAsync({});
    setDataSync(res?.data)
    console.log(res);
    console.log('Despues de la llamada a syncData.mutate');

    if (res?.data.code === 200) {
      setLoading(false);
      refetch();
      console.log(users);

      //handleCloseSync();
    }

    if (res?.status !== 200) {
      setLoading(false);
      //handleCloseSync();
    }

    /*   try {
   
        setTimeout(async () => {
          await syncData.mutate({});
          refetch();
          setLoading(false);
          handleCloseSync();
        }, 4000);
        console.log('Después de la llamada a syncData.mutate');
      } catch (error) {
        console.error('Error al sincronizar datos:', error);
      } finally {
        setLoading(false);
        handleCloseSync();
      } */
  };


  const handleConfirmation = () => {
    handleSync()
    reset({
      sku: '',
      name: '',
      ruc: '',
      unit_measure: [],
      entity_supplier_id: '',
      event_code: '',
      taxonomy_category_id: '',
      status: [],
      content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx,
    })
    setFilter({ content_event_id: eventSelectedCtx == 0 ? '' : eventSelectedCtx })
    setNotResult(false)
    setNotResultFilter(false)
    setClearStatus([])
    setSearch('')
    handleCloseConfirmation()
  }

  useEffect(() => {
    setPage(1)
    const fetchData = async () => {
      const response = await refetch()
      //console.log(response.data);

      setNotResult(response?.data?.data?.result?.data.length > 0 ? false : true)
      setNotResultFilter(
        response?.data?.data?.result?.data.length == 0 &&
        !(
          (Object.keys(filter).length == 1 && Object.keys(filter)[0] == 'content_event_id') ||
          Object.keys(filter).length == 0
        )
      )
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, refetch, filter, search])

  return (
    <div className=''>
      <div className='title'>Lista de Surtidos</div>

      <div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap border-0 pt-6'>
        {/* begin::Search */}
        <SearchFilter manageSearch={setSearch} name='surtido'/>
        {/* end::Search */}
        <div className='row w-sm-325px mt-4 mt-sm-0 d-flex justify-content-end'>
          <div className='col-6 col-sm-5'>
            <button
              type='button'
              className='btn btn-outline btn-outline-primary w-100 '
              onClick={handleShow}
            >
              <IconFilter className='me-2' />
              Filtros
            </button>
          </div>
          {(userLogin?.capability_permissions.includes('content_product_sync') || userLogin?.admin == 1) && (<div className='col-6 col-sm-7'>
            <button
              type='button'
              className='btn btn-primary w-100 '
              onClick={handleShowConfirmation}
            >
              <IconSync className='me-3' />
              Sincronizar
            </button>
          </div>)}
        </div>
      </div>

      <div className='mt-6'>
        <div className='overflow-auto h-500px'>
          <TableLitAssortments
            refetched={isRefetching}
            refetch={refetch}
            data={users?.data?.result?.data}
            isNotResult={notResult}
            isNotResultFilter={notResultFilter}
          />
        </div>
      </div>

      {notResult || notResultFilter ? null : paginationComponent()}

      {/* Filtros */}
      <div className='d-none'>
        <FormProvider {...methods}>
          <FilterOffCanvas
            filter={filter}
            setFilter={setFilter}
            handleClose={handleClose}
            handleShow={handleShow}
            show={show}
            clearStatus={clearStatus}
          />
        </FormProvider>
      </div>

      <ModalConfirmation
        title='¿Estas seguro que deseas sincronizar los productos?'
        textButton='Si, sincronizar'
        onHide={handleCloseConfirmation}
        show={showConfirmation}
        onClick={handleConfirmation}
      />
      <ModalSync
        data={dataSync}
        show={showSync}
        loading={loading}
        onClose={handleCloseSync}
      />
    </div>
  )
}
