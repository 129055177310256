import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import DropdownIcon from '../../../../../commons/components/overlay/DropdownIcon'
import {IconEye, IconSearch2} from '../../../../../commons/icons'
import {Order} from '../../../../../commons/interfaces/order/orderList'
import OrderStatusBadges from '../../../../../commons/components/badges/OrderStatusBadges'

interface IProps {
  data: Order[] | undefined
  refetched: boolean
  isNotResult?: boolean
  isNotResultFilter?: boolean
}

export const TableOrders = ({data, refetched, isNotResult, isNotResultFilter}: IProps) => {
  const navigation = useNavigate()

  const redirectDetail = (item: Order) => {
    navigation(`/pedidos/detalle/${item.id}`, {
      state: {item},
    })
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded '>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-50px'> </th>
            <th className='min-w-100px'>ID del pedido</th>
            <th className='min-w-100px'>Tipo de doc.</th>
            <th className='min-w-100px'># de doc.</th>
            <th className='min-w-150px'>Nombres</th>
            <th className='min-w-175px'>Apellidos</th>
            <th className='min-w-100px'>Estado</th>
            <th className='min-w-150px'>Código del evento</th>
            <th className='min-w-175px'>Nombre del evento</th>
            <th className='min-w-100px'>Cant. de prod.</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => {
            return (
              <tr key={item.id}>
                <td>
                  <div className='d-flex justify-content-center'>
                    <DropdownIcon>
                      <>
                        <div
                          onClick={() => redirectDetail(item)}
                          className='cursor-pointer menu-item px-5 py-4 d-flex justify-content-center align-items-center gap-3'
                        >
                          <div className='w-25'>
                            <IconEye color='#00A160' />
                          </div>

                          <div className='text-label fw-lighter tw-green-700'>Detalle</div>
                        </div>
                      </>
                    </DropdownIcon>
                  </div>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.order_as400_id}</span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    {item.purchase_order?.document_type}
                  </span>
                </td>
                <td>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    <span className='text-muted me-2 fs-7 fw-semibold'>
                      {item.purchase_order?.document_number}
                    </span>
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item.purchase_order?.entity_customer?.firstname}
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>{`${item.purchase_order?.entity_customer?.lastname} ${item.purchase_order?.entity_customer?.middlename}`}</span>
                </td>
                <td className='d-flex'>
                  <OrderStatusBadges status={item.state} />
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item.content_event?.code}
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item.content_event?.name}
                  </span>
                </td>
                <td>
                  <span className='text-muted me-2 fs-7 fw-semibold'>
                    {item.generated_order_item_count}
                  </span>
                </td>
              </tr>
            )
          })}
          {isNotResult && (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  {isNotResultFilter && (
                    <p style={{fontWeight: 300}} className=' text-label mb-2'>
                      No se han encontrado resultados en esta búsqueda.
                    </p>
                  )}
                  <p className='text-label tw-green-700'>
                    {isNotResultFilter
                      ? 'Por favor, intenta otra vez.'
                      : 'No se han encontrado resultados en esta búsqueda.'}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}
