import React, { useEffect, useState } from 'react'
import IconFilter from '../../../../../commons/icons/IconSearch'
import {
  IconAlertCircle,
  IconCalculator,
  IconCheckCircle,
  IconDotsVertical,
  IconExit,
  IconIncrement,
  IconPencil,
  IconPrint,
  IconSave,
  IconSync,
} from '../../../../../commons/icons'
import { TableListDetailWishList } from '../tables/TableListDetailWishList'
import { usePaginator } from '../../../../../commons/hooks/usePaginator'
import wishlistService from '../../../../../services/whislist'
import DropdownIcon from '../overlay/DropdownIcon'
import { ModalConfirmation } from '../../../../../commons/components/modals/ModalConfirmation'
import useModal from '../../../../../commons/hooks/useModal'
import { ModalAddProduct } from '../modals/ModalAddProduct'
import {
  ResultWishDetailList,
  datajson,
} from '../../../../../commons/interfaces/wishList/wishListList'
import { Result } from '../../../../../commons/interfaces/wishList/wishListShow'
import { ModalInvoice } from '../modals/ModalInvoice'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import clsx from 'clsx'
import ReactRouterPrompt from 'react-router-prompt'
import { ModalsConfirmWishlish } from '../modals/ModalsConfirmWishlish'
import customerService from '../../../../../services/customer'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import jsPDF from 'jspdf'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import autoTable from 'jspdf-autotable'
import { ModalErrors } from '../../../../../commons/components/modals/ModalErrors'
import { useErrorHandling } from '../../../../../commons/hooks/useErrorHandling'

interface IProps {
  data?: any
  entityCustomerId?: any
  contentEventId?: any
  //   onSelectedItemsChange: (items: any[]) => void;

  formDisabled?: boolean
  customer?:any
}
const pageSizes = [10, 20, 50]

export const WishlistProducts = ({ data, entityCustomerId, contentEventId,customer }: IProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient();

  const { errorType, handleApiError, clearError } = useErrorHandling();
  const { show, handleClose: close, handleShow } = useModal()
  const { show: showInvoice, handleClose: closeInvoice, handleShow: openInvoice } = useModal()
  const { show: showSave, handleClose: closeSave, handleShow: openSave } = useModal()
  const [filter, setFilter] = useState({})
  const [searchValue, setSearchValue] = useState('')
  const [notResult, setNotResult] = useState(false)
  const [notResultFilter, setNotResultFilter] = useState(false)
  const [sendBackend, setSendBackend] = useState<any>([])
  const [countSelected, setCountSelected] = useState<any>([])
  const [isBlocking, setIsBlocking] = useState(false)

  const dataShow = localStorage.getItem('DataShow')
  const {
    data: dataWishlist,
    error,
    isLoading,
    isRefetching,
    refetch,
    isFetching,
    isError,
  } = useQuery('listDetailWishlist', () => wishlistService.listWhislistDetail(entityCustomerId));

  const userMutation = useMutation({
    mutationFn: wishlistService.cartItemCreateUpdate,
    onSuccess: () => {
      toast.success('Los cambios en el pre-pedido fueron guardados con éxito.', {
        position: "bottom-right",
        autoClose: 5000, // Tiempo de cierre automático en milisegundos
        hideProgressBar: true, // Mostrar barra de progreso
        closeOnClick: true, // Cerrar al hacer clic
        draggable: true, // Arrastrable
        progress: undefined, // Personalizar la barra de progreso
        icon: <IconCheckCircle />,
        bodyClassName: "custom-toast-success"
      });
      //   setShowModalSuccess(false)
      // Invalida la caché de la consulta de usuarios para forzar una recarga.
      queryClient.invalidateQueries('listDetailWishlist');
      setIsBlocking(false)
      setSendBackend([])
      //navigation('/eventos/listar')
    },
    onError: (e: any) => {
      handleApiError(e)
    },
  })


  const generarPdf = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size)
    const title = `Listado de productos de pre-pedido: ${customer?.firstname} ${customer?.lastname} ${customer?.middlename}`;
    const headers = [[
      "Código",
      "Descripción",
      "Marca",
      "Cantidad solicitada",
      "Master",
      "Unidad de medida",
    ]];

    doc.text(title, marginLeft, 100);
    doc.addImage(toAbsoluteUrl('/media/logos/logo.png'), 'PNG', 40, 20, 50, 50);
    autoTable(doc, { html: '#my-table-report' })
    autoTable(doc, {
      startY: 120,
      head: headers,
      body: dataWishlist?.data?.result.map(item => { 
        return [
          item.sku,
          item.name,
          item.brand,
          item.quantity ? item.quantity.toString() : '-',
          item.master_package,
          item.unit_measure
        ]
      }),
      headStyles: { lineWidth: 0.5, fillColor: [0, 161, 96], textColor: [255, 255, 255] },
    })
    //doc.save(`resumen-whislist-${entityCustomerId}-${timeStamp}.pdf`)
    window.open(doc.output('bloburl'), '_blank');
  }

  const handleStoreSelectedData = (selectedItemsArray) => {
    const sendItems = selectedItemsArray.map((item) => ({
      entity_customer_id: item.entity_customer.id,
      content_product_id: item.id,
      quantity: item.quantity,
      selected: item.selected,
    }))

    setSendBackend(sendItems)
    if (sendBackend !== 0) {
      setIsBlocking(true)
    }
  }
  const handleConfirmation = () => {
    generarPdf()
    //console.log('imprimir y cerrar modal')
  }

  const handleSubmit = () => {
    //enviar el mutation

    const payload = { shopping_list: sendBackend }
    userMutation.mutate(payload)
  }

  const forceSave = () => {
    handleSubmit()
    setIsBlocking(false)
  }

  const redirectEditWishlist = () => {
    navigate(`/wishlist/cliente/${contentEventId}/${entityCustomerId}`)
  }

  useEffect(() => {
    setNotResult(false)
  }, [filter])


  if (isLoading || isFetching) {
    return <div>Cargando...</div>
  }

  if (isError) {
    return <div>Error al cargar los eventos</div>
  }

  return (
    <div className=''>
      <h2 className='title-form fw-bold mb-5'>Listado de productos de pre-pedido</h2>
      <div className='d-block  d-sm-flex justify-content-between gap-3 flex-wrap flex-md-nowrap border-0 mt-6'>
        <div className='card-title '>
          <input
            type='text'
            className='form-control  w-200 w-sm-250px'
            placeholder='Buscar producto del pre-pedido'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div className='d-flex  flex-wrap   gap-3 mt-4 mt-sm-0'>
          <h2 className=' fw-medium text-label mt-3'>Productos seleccionados</h2>

          <div className='card-title '>
            <input
              value={countSelected}
              readOnly
              type='text'
              className='form-control  w-40 w-sm-40px'
              placeholder='Buscar producto del pre-pedido'
            />
          </div>
          <button
            onClick={openSave}
            disabled={sendBackend.length === 0}
            type='button'
            className={clsx(
              'btn btn-outline w-120 w-sm-140px',
              sendBackend.length === 0
                ? 'btn-outline-disabled text-gray-400'
                : 'btn-outline-primary'
            )}
          >
            <IconSave className='me-2' stroke={sendBackend.length === 0 ? '#DCDCDC' : '#00A160'} />
            Guardar
          </button>

          <button
            // disabled={countSelected === 0 || sendBackend.length !== 0}
            disabled={countSelected === 0 || sendBackend.length !== 0}
            onClick={openInvoice}
            type='button'
            className='btn  btn-primary  w-100 w-sm-175px'
          >
            <IconCalculator className='me-3' stroke='#fff' />
            Generar Pedido
          </button>

          <div className='d-flex justify-content-start'>
            <DropdownIcon>
              <div className='w-180px'>
                <div
                  onClick={handleShow}
                  className=' cursor-pointer menu-item px-5 py-2 d-flex justify-content-strt align-items-start gap-2'
                >
                  <IconPrint stroke='#00A160' />

                  <div className='text-label fw-lighter tw-green-700'>Imprimir</div>
                </div>
                <div
                  onClick={redirectEditWishlist}
                  className='cursor-pointer menu-item px-5 pt-5 d-flex justify-content-start align-items-center gap-2'
                >
                  <div className=''>
                    <IconPencil stroke='#00A160' />
                  </div>

                  <div className='text-label fw-lighter tw-green-700'>Editar pre-pedido</div>
                </div>
              </div>
            </DropdownIcon>
          </div>

          {/* )} */}
        </div>
      </div>

      <div className='mt-6'>
        <div className='overflow-auto h-500px'>
          <TableListDetailWishList
            onStoreSelectedData={handleStoreSelectedData}
            refetched={isRefetching}
            searchValue={searchValue}
            data={dataWishlist?.data}
            isNotResult={notResult}
            isNotResultFilter={notResultFilter}
            setCountSelected={setCountSelected}
          />
        </div>
      </div>
      <ModalConfirmation
        title='¿Estás seguro que deseas imprimir el pre-pedido del usuario?'
        textButton='Si, imprimir'
        onHide={close}
        show={show}
        onClick={handleConfirmation}
      />
      <ModalsConfirmWishlish
        show={showInvoice}
        onHide={closeInvoice}
        idClient={entityCustomerId}
        data={dataShow}
        dataIdProducts={dataWishlist?.data}
      />
      {/* <ModalInvoice show={showInvoice} onHide={closeInvoice} /> */}
      <ModalConfirmation
        title='Se han realizado cambios en el pre-pedido. ¿Desea guardar antes de salir?'
        textButton='Sí, guardar'
        onHide={closeSave}
        show={showSave}
        onClick={handleSubmit}
      />
      {errorType && (
        <ModalErrors errorType={errorType} onClose={clearError} />
      )}

      {/* <button
        type='button'
        onClick={() => setIsBlocking(true)} >
        Hacer cambios no guardados
      </button> */}
      <ReactRouterPrompt when={isBlocking}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <div className='modal-background'>
              <div className='modal-card'>
                <div className='position-relative h-auto'>
                  <div className='mt-2'>
                    <div className='d-flex flex-column align-items-center'>
                      <IconAlertCircle />
                      <h4 className='fs-3 px-5 text-center fw-medium tw-neutral-800 mt-8'>
                        Tienes cambios no guardados. ¿Deseas guardar los cambiar antes de salir?
                      </h4>
                    </div>

                    <div className='d-grid gap-2 mt-7'>
                      <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-primary '
                        onClick={() => {
                          onConfirm(true)
                          forceSave()
                        }}
                      >
                        Si, guardar
                      </button>
                    </div>

                    <div className='d-grid gap-2 mt-7'>
                      <button
                        type='button'
                        id='kt_sign_in_submit'
                        className='button-output'
                        onClick={() => {
                          setIsBlocking(false)
                          onConfirm(true)
                        }}
                      >
                        No, salir sin guardar
                      </button>
                    </div>
                  </div>

                  <div onClick={onCancel} className='position-absolute top-0 end-0 cursor-pointer'>
                    <IconExit fill='#7C7C7C' width={14} height={14} />
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </ReactRouterPrompt>
      {/*     {notResult || notResultFilter ? null : paginationComponent()} */}
    </div>
  )
}
