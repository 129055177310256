import {useNavigate} from 'react-router-dom'
import {IconDot, IconEye, IconSearch2} from '../../../../../commons/icons'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {useEffect, useState} from 'react'

import clsx from 'clsx'
import {Daum} from '../../../../../commons/interfaces/assortment/AssortmentList'
import DropdownIcon from '../../../../../commons/components/overlay/DropdownIcon'

interface IProps {
  data: Daum[] | undefined
  refetched: boolean
  refetch: any
  isNotResult?: boolean
  isNotResultFilter?: boolean
}
export const TableLitAssortments = ({
  data,
  refetched,
  refetch,
  isNotResult,
  isNotResultFilter,
}: IProps) => {
  const [expandedCells, setExpandedCells] = useState({})
  const navigation = useNavigate()

  const redirectDetail = (item: Daum) => {
    navigation(`/surtido/detalle/${item.id}`, {
      state: {item},
    })
  }

  const toggleText = (id: number, field: string) => {
    setExpandedCells((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [field]: !prevState[id]?.[field],
      },
    }))
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [refetched])

  return (
    <div className='table-responsive rounded'>
      {/* begin::Table */}
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}

        <thead className='bg-head-table '>
          <tr className='fw-medium text-label'>
            <th className='min-w-50px'> </th>
            <th className='min-w-100px'>SKU</th>
            <th className='min-w-400px'>Descripción del SKU</th>
            <th className='min-w-150px'>Unidad de medida</th>
            <th className='min-w-150px'>Marca</th>
            <th className='min-w-150px'>Linea</th>
            <th className='min-w-350px'>Razón social</th>
            <th className='min-w-150px'>Código del Evento</th>
            <th className='w-125px'>Estado</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {data?.map((item) => {
            const isNameExpanded = expandedCells[item.id]?.name
            const isSupplierExpanded = expandedCells[item.id]?.supplier
            return (
              <tr key={item.id}>
                <td>
                  <div className='d-flex justify-content-center'>
                    <DropdownIcon>
                      <div
                        onClick={() => redirectDetail(item)}
                        className='cursor-pointer menu-item px-5 d-flex align-items-center gap-3'
                      >
                        <IconEye color='#00A160' />
                        <div className='text-label fw-lighter tw-green-700'>Detalle</div>
                      </div>
                    </DropdownIcon>
                  </div>
                </td>
                <td>
                  <span className='tw-neutral-600 fw-light tw-neutral-600 d-block fs-7'>
                    {item.sku}
                  </span>
                </td>
                <td className='tw-neutral-600 fw-light'>
                  {item.name.length > 60 ? (
                    <span className='cursor-pointer' onClick={() => toggleText(item.id, 'name')}>
                      {isNameExpanded ? item.name : `${item.name.substring(0, 60)} ...`}
                    </span>
                  ) : (
                    item.name
                  )}
                </td>
                <td>
                  <span className='tw-neutral-600 fw-light tw-neutral-600 d-block fs-7'>
                    {item.unit_measure}
                  </span>
                </td>
                <td>
                  <span className='tw-neutral-600 fw-light tw-neutral-600 d-block fs-7'>
                    <span className='tw-neutral-600 me-2 fs-7 fw-light'>{item.brand}</span>
                  </span>
                </td>
                <td>
                  <span className='tw-neutral-600 fw-light tw-neutral-600 d-block fs-7'>
                    <span className='tw-neutral-600 me-2 fs-7 fw-light'>{item.line}</span>
                  </span>
                </td>
                <td className='text-start tw-neutral-600 fw-light'>
                  {item.entity_supplier?.business_name.length > 60 ? (
                    <span
                      className='cursor-pointer'
                      onClick={() => toggleText(item.id, 'supplier')}
                    >
                      {isSupplierExpanded
                        ? item.entity_supplier?.business_name
                        : `${item.entity_supplier?.business_name.substring(0, 60)} ...`}
                    </span>
                  ) : (
                    item.entity_supplier?.business_name
                  )}
                </td>
                <td>
                  <span className='tw-neutral-600 fw-light tw-neutral-600 '>
                    {item.content_event.code}
                  </span>
                </td>
                <td>
                  <span className='fw-light'>
                    {item.status === 1 ? (
                      <div className='state-active fw-bold w-75px d-flex '>
                        <div className='d-flex align-items-center'>
                          <IconDot height={7} width={7} />
                          <div className='ms-2'> Activo</div>
                        </div>
                      </div>
                    ) : item.status === 3 ? (
                      <div className='state-pending fw-bold w-100px d-flex '>
                        <div className='d-flex align-items-center'>
                          <IconDot fill='#F59E0B' height={7} width={7} />
                          <div className='ms-2'> Pendiente</div>
                        </div>
                      </div>
                    ) : (
                      <div className='state-inactive fw-bold w-90px d-flex '>
                        <div className='d-flex align-items-center'>
                          <IconDot fill='#EF4444' height={7} width={7} />
                          <div className='ms-2'> Inactivo</div>
                        </div>
                      </div>
                    )}
                  </span>
                </td>
              </tr>
            )
          })}
          {isNotResult && (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center mt-5'>
                  <IconSearch2 />
                </div>
                <div className='d-flex flex-column justify-content-center mt-5 text-center'>
                  {isNotResultFilter && (
                    <p style={{fontWeight: 300}} className=' text-label mb-2'>
                      No se han encontrado resultados en esta búsqueda.
                    </p>
                  )}
                  <p className='text-label tw-green-700'>
                    {isNotResultFilter
                      ? 'Por favor, intenta otra vez.'
                      : 'No se han encontrado resultados en esta búsqueda.'}
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
        {/* end::Table body */}
      </table>
      {/* end::Table */}
    </div>
  )
}
