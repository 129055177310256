import { IAssortmentList } from '../../commons/interfaces/assortment/AssortmentList'
import { IAssortmentShow } from '../../commons/interfaces/assortment/AssortmentShow'
import { IAssortmentSync } from '../../commons/interfaces/assortment/AssortmentSync'
import {IUserPersonCreate} from '../../commons/interfaces/userPerson/userPersonCreate'
import {IUserPersonState} from '../../commons/interfaces/userPerson/userPersonState'
import {IUserPersonUpdate} from '../../commons/interfaces/userPerson/userPersonUpdate'
import api from '../../config/axios'

const assortmentService = {
  list: async (page: number | string, limit: number | string, search: string, filters = {} as any) => {
    try {
      const response = await api.get<IAssortmentList>(
        `/api/content/product/list?page=${page}&limit=${limit}&search=${search ?? ''}&sku=${filters.sku ?? ''}&name=${filters.name ?? ''}&unit_measure=${filters.unit_measure ?? ''}&ruc=${filters.ruc ?? ''}&entity_supplier_id=${filters.entity_supplier_id ?? ''}&event_code=${filters.event_code ?? ''}&taxonomy_category_id=${filters.taxonomy_category_id ?? ''}&status=${filters.status ?? ''}&content_event_id=${filters.content_event_id ?? ''}`
      )
      return response
    } catch (error) {
      console.log(error)
    }
  },
  show: async (id: number) => {
    try {
      const response = await api.get<IAssortmentShow>(`/api/content/product/show?id=${id}`)
      return response
    } catch (error) {
      console.log(error)
    }
  },
  sync: async (data) => {
    try {
      const response = await api.patch<IAssortmentSync>('/api/content/product/sync', data)
      return response
    } catch (error) {
      console.log(error)
    }
  },
  /* 
  create: async (data) => {
    try {
      const response = await api.post<IUserPersonCreate>('/api/user/person', data)
      return response
    } catch (error) {
      throw error
    }
  },
  update: async (data) => {
    try {
      const response = await api.put<IUserPersonUpdate>('/api/user/person', data)
      return response
    } catch (error) {
      throw error
    }
  },
  state: async (data: {id: number; status: number}) => {
    try {
      const response = await api.patch<IUserPersonState>('/api/user/person/state', data)
      return response
    } catch (error) {
      throw error
    }
  }, */

}

export default assortmentService
