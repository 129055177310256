/**
 * Component for managing draggable and droppable forms using react-beautiful-dnd.
 *
 * @component
 * @example
 * return (
 *   <DndSection
 *     defaultForm={defaultForm}
 *     initialForms={initialForms}
 *     formName="information-block"
 *     prefixCode="IB"
 *     formDisabled={false}
 *     addButtonText="Add Form"
 *     handleFunction={handleFunction}
 *     formRules={formRules}
 *   />
 * )
 */
import React, {useState} from 'react'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import SVG from 'react-inlinesvg'
import InformationBlockForm from './InformationBlockForm'
import {Trash} from '../../icons'
import IconPosition from '../../icons/IconPosition'
import IconPlus from '../../icons/IconPlus'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ModalSuccess} from '../../../app/modules/clients/components/modals/ModalSuccess'
import BannerForm from './BannerForm'
import {FileUploaded} from '../../interfaces/managementFile/managementFileCreate'
import ActivityForm from './ActivityForm'
import {ModalAlert} from '../modals/ModalAlert'
import GalleryForm from './GalleryForm'
import CatalogueForm from './CatalogueForm'

/**
 * Represents the props for the DndSection component.
 */
interface IDndSectionProps {
  /**
   * The default form.
   */
  defaultForm: any

  /**
   * The initial forms.
   */
  initialForms: any[]

  /**
   * The name of the form.
   */
  formName: string

  /**
   * The prefix code.
   */
  prefixCode: string

  /**
   * Indicates whether the form is disabled.
   */
  formDisabled: boolean

  /**
   * The text for the add button.
   */
  addButtonText?: string

  /**
   * The function to handle the form data.
   * @param data The form data.
   */
  handleFunction: (data: any) => void

  /**
   * The rules for the form.
   */
  formRules?: any
}
interface IDndSectionProps {
  defaultForm: any
  initialForms: any[]
  formName: string
  prefixCode: string
  formDisabled: boolean
  addButtonText?: string
  handleFunction: (data: any) => void
  formRules?: any
}
/**
 * Component for managing draggable and droppable forms using react-beautiful-dnd.
 * @param props - The props of the component.
 * @param props.defaultForm - The default form.
 * @param props.initialForms - The initial forms.
 * @param props.formName - The name of the form.
 * @param props.prefixCode - The prefix code.
 * @param props.formDisabled - Indicates whether the form is disabled.
 * @param props.addButtonText - The text for the add button.
 * @param props.handleFunction - The function to handle the form data.
 * @param props.formRules - The rules for the form.
 * @returns The DndSection component.
 * @example
 * return (
 *  <DndSection
 *    defaultForm={defaultForm}
 *    initialForms={initialForms}
 *    formName="information-block"
 *    prefixCode="IB"
 *    formDisabled={false}
 *    addButtonText="Add Form"
 *    handleFunction={handleFunction}
 *    formRules={formRules}
 *  />
 * )
 */
const DndSection: React.FC<IDndSectionProps> = (props) => {
  // Destructuring props for easier access
  const {
    defaultForm,
    initialForms,
    formName,
    prefixCode,
    formDisabled,
    addButtonText = 'Añadir formulario',
    handleFunction,
    formRules,
  } = props

  // State variables
  const [forms, setForms] = useState<any[]>(
    // Initialize forms with the initialForms data and add the cardTitle and code properties
    initialForms.map((form, index) => ({
      ...form,
      cardTitle: `${prefixCode} #${index + 1}`,
      code: `${prefixCode} #${index + 1}`,
    })) || []
  )
  const [showModalAlert, setShowModalAlert] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)

  // Function to delete a form of the list
  const deleteForm = (code: string) => {
    setForms(forms.filter((form) => form.code !== code))
  }
  // Function to add a new form to the list
  const addForm = () => {
    const cardTitle = `${prefixCode} #${forms.length + 1}`
    // Generate a unique id for the form
    const uniqueId = Math.random().toString(36).substr(2, 9)
    // Generate a timestamp for the form
    const timestamp = Date.now().toString()
    // Create the new form with the default form, the card title, and the generated code
    const newForm = {
      ...defaultForm,
      cardTitle: cardTitle,
      code: `${prefixCode}-${timestamp}-${uniqueId}`,
    }
    // Add the new form to the list of forms
    setForms([...forms, newForm])
  }
  // Function to handle the drag and drop of the forms
  const onDragEnd = (result: any) => {
    if (!result.destination) return
    const items = Array.from(forms)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setForms(items)
  }
  // Function to update a form with the new value of a field
  const updateForm = (code: string, field: string, value: string | FileUploaded[] | FileUploaded | null) => {
    // Create a new list of forms with the updated form
    let newForms: any[] = []
    // Iterate over the forms to find the form with the code to update
    forms.forEach((form) => {
      // If the form has the code to update, update the field with the new value
      if (form.code === code) {
        // Add the updated form to the new list of forms
        newForms.push({...form, [field]: value})
      } else {
        // Add the form without changes to the new list of forms
        newForms.push(form)
      }
    })
    // Update the list of forms with the new list of forms
    setForms(newForms)
  }
  // Function to handle the submit of the forms
  const onSubmit = async () => {
    // Call the handleFunction of the parent component
    handleFunction(forms)
    // show the success modal
    setShowModalSuccess(false)
  }
  // Function to render the form based on the form name
  const renderForm = (element) => {
    if (formName === 'information-block') {
      return (
        <InformationBlockForm element={element} onChange={updateForm} formDisabled={formDisabled} />
      )
    }
    if (formName === 'banner') {
      return <BannerForm element={element} onChange={updateForm} formDisabled={formDisabled} />
    }
    if (formName === 'activity') {
      return <ActivityForm element={element} onChange={updateForm} formDisabled={formDisabled} />
    }
    if (formName === 'gallery') {
      return <GalleryForm element={element} onChange={updateForm} formDisabled={formDisabled} />
    }
    if (formName === 'catalogue') {
      return <CatalogueForm element={element} onChange={updateForm} formDisabled={formDisabled} />
    }
  }
  // Function to validate the forms
  const validateForms = () => {
    // If there are form rules, validate the required fields
    if (formRules) {
      // Get the required keys of the form rules
      const requiredKeys = Object.keys(formRules).filter((key) => formRules[key].required)
      // Iterate over the forms to validate the required fields
      for (const obj of forms) {
        // Iterate over the required keys of the form rules
        for (const key of requiredKeys) {
          // If the form does not have the required key or the array is empty, return false
          if (!obj[key] || (Array.isArray(obj[key]) && obj[key].length === 0)) {
            return false
          }
        }
      }
    }
    return true
  }
  // Function to submit the forms
  const submit = () => {
    // If the forms are valid, show the success modal, otherwise show the alert modal
    if (validateForms()) {
      setShowModalSuccess(true)
      return
    }
    setShowModalAlert(true)
    return
  }

  return (
    <>
      {/* Render the forms with the drag and drop context */}
      <DragDropContext onDragEnd={onDragEnd}>
        {/* Render the droppable area for the forms */}
        <Droppable droppableId='forms'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {forms.map((element, index) => (
                <Draggable key={element.code} draggableId={element.code} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {/* Render the form with the card title and the form fields */}
                      <div className='card p-4 d-flex flex-colum' style={{marginBottom: '20px'}}>
                        <div className='d-flex justify-content-between p-4 position-relative'>
                          <div
                            className='position-absolute'
                            style={{top: '-20px', left: 'calc(50% - 20px)'}}
                          >
                            <IconPosition />
                          </div>
                          <span className='card-event-title'>{element.cardTitle}</span>
                          {!formDisabled && (
                            <button
                              type='button'
                              className='btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3'
                              onClick={() => deleteForm(element.code)}
                            >
                              <Trash />
                            </button>
                          )}
                        </div>
                        {/* Render the form based on the form name */}
                        {renderForm(element)}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {/* Render the add button for the forms */}
        <div className='d-flex justify-content-center mb-6 mt-6'>
          <button
            className='btn btn-outline btn-outline-primary me-2 d-flex align-items-center gap-3'
            onClick={addForm}
            disabled={formDisabled}
          >
            <IconPlus />
            {addButtonText}
          </button>
        </div>
        {/* Render the submit button for the forms */}
        <div className='d-flex justify-content-center'>
          <button
            className='btn btn-primary me-2  d-flex align-items-center gap-3'
            onClick={submit}
            disabled={formDisabled || forms.length === 0}
          >
            <SVG src={toAbsoluteUrl('/media/svg/general/check.svg')} />
            Guardar cambios
          </button>
        </div>
      </DragDropContext>
      {/* Render the modals for the alerts */}
      <ModalAlert show={showModalAlert} onHide={() => setShowModalAlert(false)} />
      {/* Render the modal for request approval */}
      <ModalSuccess
        onSubmit={onSubmit}
        show={showModalSuccess}
        onHide={() => setShowModalSuccess(false)}
      />
    </>
  )
}

export default DndSection
